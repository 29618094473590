<template>
  <v-card>
    <v-card-title>
      OOH Store Data
      <v-spacer></v-spacer>
      <v-text-field
        class="pr-4"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-dialog v-model="dialogForm" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle  }}</span>
          </v-card-title>

          <v-card-text>
            Please avoid special symbols and capitalize words correctly. Double check spellings to make sure that it matches the existing data!
            
            <v-text-field
              v-model="formItem['Store Name']"
              label="Store Name"
            ></v-text-field>
            <v-select
              v-model="formItem['Place']"
              :items="defaultPlaces"
              label="Place"
            ></v-select>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="saveEditForm">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteRowConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="data"
      item-key="name"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="showEditForm(item)">edit</v-icon>
        <v-icon small class="mr-2" @click="deleteRow(item)">delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      editedIndex: -1,
      dialogTitle: "",
      dialogForm: false,
      dialogDelete: false,
      data: [],
      search: "",
      headers: [
        { text: "Store Name",  value: "Store Name", sortable: true },
        { text: "Place", value: "Place", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ],
      formItem: this.defaultFormData(),
      defaultPlaces: [
        "Convenience store – eg. 7 eleven, my news etc",
        "General Trade - CMH (Chinese Medical Hall) and provision shop (kedai runcit)",
        "Hawker stall/Mamak/Chinese Coffee Shop/Food Courts",
        "Hypermarkets – eg: Giant, Tesco etc",
        "Mini Market – eg. 99 Speedmart, KK Mart etc",
        "Petrol Station", 
        "Pharmacy",
        "Supermarkets – eg. Aeon, Jaya Grocer etc",
        "Vending machine",
        "Western/ Lifestyle cafe",
        "Restaurant",
        "R&R",
        "Cinema",
        "Bakery",
        "Coffee Joints",
        "Roadside stalls",
        "Food truck",
        "Others"
      ]
    };
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  methods: {
    defaultFormData() {
      return {
        "Store Name": "",
        Place: "",
      }
    },

    listOohStoreData() {
      this.$setLoader()

      service.listOohStoreData({}).then(data => {
        this.data = data.data
        this.$disableLoader()
      })
    },

    deleteRow(row) {
      this.formItem = row
      this.dialogDelete = true
    },

    deleteRowConfirm() {
      this.$setLoader()

      service.deleteOohStoreData(this.formItem).then(() => {
        this.closeDialog()
        this.listOohStoreData()
      })
    },

    closeDialog() {
      this.formItem = this.defaultFormData()
      this.dialogForm = false
      this.dialogDelete = false
      this.editedIndex = -1
    },

    showEditForm(row) {
      this.editedIndex = 0
      this.formItem = row

      this.dialogForm = true
    },

    saveEditForm() {
      for (let key of Object.keys(this.formItem)) {
        if (this.formItem[key] == null) {
          this.formItem[key] = ""
        } 

        if (typeof this.formItem[key] === 'string' || this.formItem[key] instanceof String) {
          this.formItem[key] = this.formItem[key].trim()
        }
      }

      eval('this.editedIndex === -1 ? service.createOohStoreData(this.formItem) : service.updateOohStoreData(this.formItem)').then(() => {
        this.formItem = this.defaultFormData()

        this.listOohStoreData()
        this.dialogForm = false
        this.editedIndex = -1
      })
    }
  },

  mounted() {
    this.listOohStoreData()
  }
};
</script>
